<template>
    <v-container fluid>

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Mach. Type" @change="(event) => machTypeChange(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machIDModel" :items="machIDLists" default="" item-value="mach_id" item-text="descr" label="Mach. ID" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <!-- <div class="col-lg-2">
                                <v-select v-model="workShiftModel" :items="workShiftLists" item-value="value" item-text="text" label="Work. Shift" dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div> -->
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" type="month" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateStartModel)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" type="month" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateEndModel)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 500px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </v-container>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'SUNRISE WEB',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production',
                disabled: false,
                href: '#',
                },
                {
                text: 'Overall Equipment Effectiveness',
                disabled: true,
                href: '#',
                },
            ],
            machTypeModel: '',
            machTypeLists: [],
            machIDModel: '',
            machIDLists: [],
            workShiftModel: '1',
            workShiftLists: [
                {
                    'value' : '1',
                    'text' : '1'
                },
                {
                    'value' : '2',
                    'text' : '2'
                },
                {
                    'value' : '3',
                    'text' : '3'
                }
            ],
            dateStartModal: false,
            dateStartModel: '',
            dateEndModal: false,
            dateEndModel: '',
            dateStartFormatted: '',
            dateEndFormatted: ''
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)
            // document.querySelector(".cardResult").style.display = "none"
            $('.cardResult').hide()

            const currentDate = new Date()
            const offsetInMinutes = currentDate.getTimezoneOffset()
            const adjustedDate = new Date(currentDate - offsetInMinutes * 60000)
            const periodOutput = adjustedDate.toISOString().substr(0, 7)

            this.dateStartModel = periodOutput
            this.dateEndModel = periodOutput

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/OverallEquipmentEffectiveness`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.machTypeLists = res.data.machType

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getData(){

            if(this.machTypeModel == null || this.machTypeModel == ''){
                Swal.fire({
                        text: 'Please Select Machine Type !',
                        icon: 'warning',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            $('.cardResult').hide()

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 7).replace(/-/g,"")
            } else {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 7).replace(/-/g,"")
            } else {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/OverallEquipmentEffectiveness/getData`, { 

                    machType: this.machTypeModel ? this.machTypeModel : '',
                    machID: this.machIDModel ? this.machIDModel : '',
                    workShift: this.workShiftModel ? this.workShiftModel : '',
                    dateFrom: this.dateStartFormatted ? this.dateStartFormatted : '',
                    dateTo: this.dateEndFormatted ? this.dateEndFormatted : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                // console.log(res.data.result);

                if(res.data.arr_list4.length != 0){
                    // document.querySelector(".cardGraph").style.display = "block";
                    $('.cardResult').show()
                    this.renderChart(res.data.arr_list4)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    // document.querySelector(".cardGraph").style.display = "none";
                    $('.cardResult').hide()
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);
            })

        },

        renderChart(data1){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                // title:{
                //     text: "Overall Equipment Effectiveness Graph"
                // },
                // subtitles: [{
                //     text: "Click for details",		
                //     fontColor: "grey",
                // }],
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    // title: "IDR x1.000.000",
                    titleFontSize: 12,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    gridColor: "#005f73",
                    labelFontSize: 12,
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    shared:true
                },
                data: [
                {

                    type: "column",
                    // color: "#639cd9",
                    indexLabel: "{y}",
                    name: 'OEE VALUE',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "horizontal",
                    // yValueFormatString: "###,##",
                    indexLabelFontColor: "#ffffff",
                    dataPoints: data1,

                }]
            });

            chart.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        async machTypeChange(id){

            console.log(id);

            if(id){

                await axios.get(`${process.env.VUE_APP_URL}/api/sr/OverallEquipmentEffectiveness/machTypeChange?machType=${this.machTypeModel ? this.machTypeModel : ''}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.machIDLists = res.data.machID

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.machIDLists = []

            }

            

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>